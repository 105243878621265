import React, { useState, useRef, useEffect } from "react";
import "./App.css";

function App() {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const webcamRef = useRef(null);
  const screenRef = useRef(null);
  const webcamRecorderRef = useRef(null);
  const screenRecorderRef = useRef(null);

  const [username, setUsername] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [game, setGame] = useState("");
  const [gamename, setGamename] = useState("");
  const [testName, setTestName] = useState("");

  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');

  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState('');

  const [showForm, setShowForm] = useState(false);
  const [xpGameType, setXpGameType] = useState(1);
  const [xpGameScore, setXpGameScore] = useState(1);
  const [comment, setComment] = useState('');
  const [screenRecordingUrl, setScreenRecordingUrl] = useState('');

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   setUsername(urlParams.get("username") || "");
  //   setLanguageCode(urlParams.get("languageCode") || "");
  //   setGame(urlParams.get("game") || "");
  //   setTestName(urlParams.get("testName") || "");

  //   requestPermissions();
  //   getVideoDevices();
  // }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUsername(urlParams.get("username") || "");
    setLanguageCode(urlParams.get("languageCode") || "");
    setGame(urlParams.get("game") || "");
    setGamename(urlParams.get("game") || "");
    setTestName(urlParams.get("testName") || "");
  
    requestPermissions();
    getInputDevices(); // Updated function name
  }, []);
  

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording]);

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      await navigator.mediaDevices.getDisplayMedia({ video: true });
    } catch (error) {
      console.error("Error requesting permissions:", error);
    }
  };

  // const handleSubmitForm = async () => {
  //   try {
  //     const response = await fetch('https://strapibt.civersia.com/api/videos', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer 09a76ed3e8fb01f630c9e10931fb3fd405b0e571a1721a1ceed1ed9ac6d7954a6f3738c7ae4bf10d4e4b4913c1a6c3e19b7940ce213ec0f5141a32fe41fe8b046bd12a8ba3ee0ca69f49393b44c873cfb73a269df9a7b89694cc281b1a485c26cafa5d89efadb5f190500fc2be5a301bf93e7aed9b547930e28ae00b3f2c0844',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         data: {
  //           xpGameType,
  //           xpGameScore,
  //           comment,
  //           username,
  //           game,
  //           testName,
  //           languageCode
  //         }
  //       })
  //     });
  
  //     if (response.ok) {
  //       console.log('Form data submitted successfully');
  //       setShowForm(false);
  //     } else {
  //       console.error('Failed to submit form data');
  //     }
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //   }
  // };
  
  

  // const getVideoDevices = async () => {
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   const videoDevices = devices.filter(device => device.kind === 'videoinput');
  //   setVideoDevices(videoDevices);
  //   if (videoDevices.length > 0) {
  //     setSelectedVideoDevice(videoDevices[0].deviceId);
  //   }
  // };

  const getInputDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    const audioDevices = devices.filter(device => device.kind === 'audioinput');
    
    setVideoDevices(videoDevices);
    setAudioDevices(audioDevices);
    
    if (videoDevices.length > 0) {
      setSelectedVideoDevice(videoDevices[0].deviceId);
    }
    if (audioDevices.length > 0) {
      setSelectedAudioDevice(audioDevices[0].deviceId);
    }
  };

  const startRecording = async () => {
    const webcamStream = await navigator.mediaDevices.getUserMedia({
      video: { 
        deviceId: selectedVideoDevice ? { exact: selectedVideoDevice } : undefined,
        width: 1195, 
        height: 672 
      },
      audio: {
        deviceId: selectedAudioDevice ? { exact: selectedAudioDevice } : undefined
      }      
      // audio: true
    });
    const screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: { width: 1280, height: 720 },
      audio: true
    });

    webcamRef.current.srcObject = webcamStream;
    screenRef.current.srcObject = screenStream;

    webcamRecorderRef.current = new MediaRecorder(webcamStream);
    screenRecorderRef.current = new MediaRecorder(screenStream);

    const webcamChunks = [];
    const screenChunks = [];

    webcamRecorderRef.current.ondataavailable = (e) => webcamChunks.push(e.data);
    screenRecorderRef.current.ondataavailable = (e) => screenChunks.push(e.data);

    webcamRecorderRef.current.onstop = () => uploadVideo(webcamChunks, 'player');
    screenRecorderRef.current.onstop = () => uploadVideo(screenChunks, 'screen');

    webcamRecorderRef.current.start();
    screenRecorderRef.current.start();
  };

  const stopRecording = () => {
    webcamRecorderRef.current?.stop();
    screenRecorderRef.current?.stop();
  };

  const uploadVideo = async (chunks, type) => {
    const blob = new Blob(chunks, { type: 'video/mp4' });
    let strapiVideoUrl = '';
    const formData = new FormData();
    formData.append('video', blob, `${type}.mp4`);
    formData.append('type', type);
    formData.append('jugador', username);
    formData.append('game', game);
    formData.append('testOptionalID', testName);
    formData.append('languageCode', languageCode);
    formData.append('postID', languageCode);

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const response = await fetch('https://beetestedback.civersia.com/upload', {
        method: 'POST',
        body: formData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      if (type === 'screen') {
        // Upload to Strapi media library
        const strapiFormData = new FormData();
        strapiFormData.append('files', blob, `${type}.mp4`);
        
        const strapiUploadResponse = await fetch('https://strapibt.civersia.com/api/upload', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer 09a76ed3e8fb01f630c9e10931fb3fd405b0e571a1721a1ceed1ed9ac6d7954a6f3738c7ae4bf10d4e4b4913c1a6c3e19b7940ce213ec0f5141a32fe41fe8b046bd12a8ba3ee0ca69f49393b44c873cfb73a269df9a7b89694cc281b1a485c26cafa5d89efadb5f190500fc2be5a301bf93e7aed9b547930e28ae00b3f2c0844'
          },
          body: strapiFormData
        });
  
        if (strapiUploadResponse.ok) {
          const uploadData = await strapiUploadResponse.json();
          strapiVideoUrl = `https://strapibt.civersia.com${uploadData[0].url}`;
          // Store URL for form submission
          setScreenRecordingUrl(strapiVideoUrl);
          setShowForm(true);
        }
      }
      if (response.ok) {
        console.log(`${type} video uploaded successfully`);
        if (type === 'screen') { // Show form after screen recording is uploaded
          setShowForm(true);
        }
      } else {
        console.error(`${type} video upload failed`);
      }
    } catch (error) {
      console.error(`Error uploading ${type} video:`, error);
    } finally {
      setIsUploading(false);
    }
  };
 
  // const uploadVideo = async (chunks, type) => {
  //   const blob = new Blob(chunks, { type: 'video/mp4' });
  //   let strapiVideoUrl = '';
    
  //   setIsUploading(true);
  //   setUploadProgress(0);
  
  //   try {
  //     if (type === 'screen') {
  //       // Upload to Strapi media library
  //       const strapiFormData = new FormData();
  //       strapiFormData.append('files', blob, `${type}.mp4`);
        
  //       const strapiUploadResponse = await fetch('https://strapibt.civersia.com/api/upload', {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': 'Bearer 09a76ed3e8fb01f630c9e10931fb3fd405b0e571a1721a1ceed1ed9ac6d7954a6f3738c7ae4bf10d4e4b4913c1a6c3e19b7940ce213ec0f5141a32fe41fe8b046bd12a8ba3ee0ca69f49393b44c873cfb73a269df9a7b89694cc281b1a485c26cafa5d89efadb5f190500fc2be5a301bf93e7aed9b547930e28ae00b3f2c0844'
  //         },
  //         body: strapiFormData
  //       });
  
  //       if (strapiUploadResponse.ok) {
  //         const uploadData = await strapiUploadResponse.json();
  //         strapiVideoUrl = `https://strapibt.civersia.com${uploadData[0].url}`;
  //         // Store URL for form submission
  //         setScreenRecordingUrl(strapiVideoUrl);
  //         setShowForm(true);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(`Error uploading ${type} video:`, error);
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };
  
  const handleSubmitForm = async () => {
    try {
      const response = await fetch('https://strapibt.civersia.com/api/videos', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer 09a76ed3e8fb01f630c9e10931fb3fd405b0e571a1721a1ceed1ed9ac6d7954a6f3738c7ae4bf10d4e4b4913c1a6c3e19b7940ce213ec0f5141a32fe41fe8b046bd12a8ba3ee0ca69f49393b44c873cfb73a269df9a7b89694cc281b1a485c26cafa5d89efadb5f190500fc2be5a301bf93e7aed9b547930e28ae00b3f2c0844',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            xpGameType,
            xpGameScore,
            comment,
            username,
            gamename,
            testName,
            languageCode,
            screenRecording: screenRecordingUrl
          }
        })
      });
  
      if (response.ok) {
        console.log('Form data submitted successfully');
        setShowForm(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  

  return (
    <div className="App">
      <header className="App-header">
        <img className="logo" src="/logo.png"></img>
      </header>
      {showForm && (
        <div className="feedback-form">
          <h2>Test Feedback</h2>
          <div className="form-group">
            <label>XP Game Type (1-5):</label>
            <input
              type="number"
              min="1"
              max="5"
              value={xpGameType}
              onChange={(e) => setXpGameType(Math.min(5, Math.max(1, parseInt(e.target.value))))}
            />
          </div>
          <div className="form-group">
            <label>XP Game Score (1-10):</label>
            <input
              type="number"
              min="1"
              max="10"
              value={xpGameScore}
              onChange={(e) => setXpGameScore(Math.min(10, Math.max(1, parseInt(e.target.value))))}
            />
          </div>
          <div className="form-group">
            <label>Comment:</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows="4"
            />
          </div>
          <button onClick={handleSubmitForm}>Submit Feedback</button>
        </div>
      )}
      <main className="App-main">
        <div className="user-info">
          <p><span>Username:</span> {username}</p>
          <p><span>Language Code:</span> {languageCode}</p>
          <p><span>Game:</span> {game}</p>
          <p><span>Test Name:</span> {testName}</p>
        </div>
        <div className="input-devices-selection">
          <div className="webcam-selection">
            <label htmlFor="webcam-select">Choose webcam: </label>
            <select 
              id="webcam-select" 
              value={selectedVideoDevice} 
              onChange={(e) => setSelectedVideoDevice(e.target.value)}
            >
              {videoDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Camera ${videoDevices.indexOf(device) + 1}`}
                </option>
              ))}
            </select>
          </div>
          
          <div className="microphone-selection">
            <label htmlFor="microphone-select">Choose microphone: </label>
            <select 
              id="microphone-select" 
              value={selectedAudioDevice} 
              onChange={(e) => setSelectedAudioDevice(e.target.value)}
            >
              {audioDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${audioDevices.indexOf(device) + 1}`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button 
          className={`record-button ${isRecording ? 'recording' : ''}`} 
          onClick={() => setIsRecording(!isRecording)}
          disabled={isUploading}
        >
          <span className="button-text">{isRecording ? 'Stop Recording' : 'Start Recording'}</span>
          <span className="button-icon">{isRecording ? '⏹' : '⏺'}</span>
        </button>
        {isRecording && (
          <div className="preview-container">
            <div className="webcam-container">
              <video ref={webcamRef} autoPlay muted className="webcam-video" width="1195" height="672" />
            </div>
            <video ref={screenRef} autoPlay muted className="preview-video" width="1280" height="720" />
          </div>
        )}
        {isUploading && (
          <div className="upload-status">
            <p>Please wait while the files are uploading...</p>
            <progress value={uploadProgress} max="100"></progress>
            <p>{uploadProgress}% Uploaded</p>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
